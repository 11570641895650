<template>
  <div>

    <b-card no-body>
      <b-card-header header-tag="nav">
        <h5 class="card-title"><i class="fas fa-link"></i> ลิงค์ดูผล</h5>

        <b-nav card-header tabs>
          <b-nav-item
            v-for="group in groups"
            :key="group._id"
            @click="activeTab=group._id"
            :active="activeTab===group._id">{{group.groupTitle}}</b-nav-item>
        </b-nav>
      </b-card-header>

      <b-card-body class="position-relative py-2">

        <div v-for="item in items" :key="item._id" class="py-2">
          <div class="d-flex">
            <div class="mt-2">
              <img v-if="item.imageIcon" :src="item.imageIcon" class="rounded" width="80" style="border: 1px solid #EEE;">
            </div>
            <div class="ml-3">
              <h5 class="mb-0">{{item.marketTitle}}</h5>
              <div><i class="far fa-times-circle text-danger"></i> ปิดรับ: {{item.closeTime | dTime}} น.</div>
              <div v-if="item.announceTime"><i class="far fa-check-circle text-success"></i> ผลออก: {{item.announceTime | dTime}} น.</div>
              <div v-if="item.url"><i class="fas fa-award text-primary"></i> ดูผล: <a :href="item.url" target="_blank">{{item.urlText || item.url}} <i class="fas fa-external-link-alt"></i></a></div>
            </div>
          </div>
        </div>

        <div v-if="!items.length" class="text-center">ไม่มีข้อมูล</div>

        <loading
          :active="isLoading"
          :can-cancel="false"
          :is-full-page="false"
          background-color="#EBEDEF"
          :height="60"
          :width="60"
          color="#007bff"
          loader="dots"
        />

      </b-card-body>
    </b-card>

  </div>
</template>
<script>
import SystemConfigService from '@/services/SystemConfigService'

export default {
  name: 'Links',
  data() {
    return {
      activeTab: null,
      data: null,
      isLoading: false
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups
    },
    items() {
      if(!this.data)
        return []

      return this.data.filter((market)=>{
        return market.groupId === this.activeTab
      })
    }
  },
  methods: {
    getMarkets() {
      this.isLoading = true
      SystemConfigService.getMarkets()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.activeTab = this.groups[0]._id
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    this.getMarkets()
  },
  filters: {
    dTime(t) {
      const times = t.split(':')
      return `${times[0]}:${times[1]}`
    }
  }
}
</script>
<style type="text/css"></style>
